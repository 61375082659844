<template>
  <main class="bg-dark payment-page">
    <div class="d-flex justify-content-center align-items-center min-vh-100">
      <div class="text-center">
        <div class="error-icon mb-3">
          <i class="bi bi-x-circle-fill"></i>
        </div>
        <h1 class="display-4 text-danger">Pembayaran Gagal</h1>
        <p class="lead text-white px-4">
          Sayangnya, transaksi Anda tidak dapat diproses saat ini.
        </p>
        <p class="text-white px-4">
          Silakan periksa detail pembayaran Anda dan coba lagi.
        </p>
        <a @click="goToHomePage()" class="btn btn-secondary mt-3">Kembali</a>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  mounted() {
    // this.validatePayload(); // disabled for failed page
  },
  methods: {
    goToHomePage() {
      this.$router.push("/");
    },
    validatePayload() {
      console.log("this.$route.query:", this.$route.query);
      if (this.$route.query.data) {
        try {
          const decodedString = atob(this.$route.query.data);
          const payload = JSON.parse(decodedString);
          console.log("payload:", payload);

          if (payload?.order_id) {
            return;
          }
        } catch (error) {
          console.error("Invalid data:", error?.message);
        }
      }
      this.goToHomePage();
    },
  },
};
</script>
