import CheckoutPage from "@/pages/CheckoutPage.vue";
import HomePage from "@/pages/HomePage.vue";
import FailedPage from "@/pages/payment/FailedPage.vue";
import SuccessPage from "@/pages/payment/SuccessPage.vue";
import Cookies from "js-cookie";
import { createRouter, createWebHistory } from "vue-router";

function isLoggedIn() {
  return !!Cookies.get("user-profile");
}
function isTotal() {
  return !!Cookies.get("Total");
}
function isCheckout() {
  return !!Cookies.get("jcash");
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/CheckoutPage",
    name: "CheckoutPage",
    component: CheckoutPage,
    beforeEnter: (to, from, next) => {
      if (isLoggedIn() && isTotal() && isCheckout()) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/success",
    name: "SuccessPage",
    component: SuccessPage,
  },
  {
    path: "/failed",
    name: "FailedPage",
    component: FailedPage,
  },
];

const router = createRouter({
  history: createWebHistory(""),
  routes,
});

export default router;
