<template>
  <main>
    <!-- Hero section -->
    <section class="hero">
      <div
        class="top-bar d-flex justify-content-between align-items-center mb-2"
      >
        <a
          href="#"
          class="btn home-button mb-2 d-flex align-items-center"
          data-bs-toggle="modal"
          data-bs-target="#logoutModal"
        >
          <div class="text-content me-2" style="color: slategray">
            <p
              class="profile-title mb-0 fw-bold text-end"
              style="color: white"
              v-if="isBed == 'true'"
            >
              .{{ isLogin }}
            </p>
            <p
              class="profile-title mb-0 fw-bold text-end"
              style="color: white"
              v-else
            >
              {{ isLogin }}
            </p>
          </div>
          <img
            :src="
              isLogin
                ? `https://minotar.net/avatar/${isLogin}.png`
                : '/img/64.png'
            "
            alt="Player Icon"
            class="recent-payment-icon1"
          />
        </a>
        <a
          href="#"
          class="btn btn-secondary login-button mb-2 d-flex align-items-center"
        >
          <i class="bi bi-reply-fill me-2"></i>
          <span class="d-none d-md-inline fw-bold" @click="goToHomePage"
            >Home</span
          >
        </a>
      </div>
      <img
        src="/img/logo.png"
        alt="MineLuxMC Store Logo"
        class="logo img-fluid"
      />
    </section>

    <!-- Store Info section -->
    <section class="store-info">
      <div class="container">
        <div class="row">
          <!-- Customer Info -->
          <div class="col-md-3">
            <div
              class="logout-section bg-dark text-light p-3 rounded mb-3 d-flex align-items-center"
            >
              <a
                href="#"
                class="text-light d-flex align-items-center text-decoration-none"
                @click="confirmLogout"
              >
                <img
                  class="avatar-head me-3"
                  :src="`https://minotar.net/avatar/${isLogin}.png`"
                />
                <div>
                  <div class="fw-bold">Klik untuk Logout</div>
                  <div class="fw-bold" style="color: lightblue">
                    {{ isLogin }}
                  </div>
                </div>
              </a>
            </div>
            <!-- Coins -->
            <div class="coins bg-dark text-light p-3 rounded mb-3">
              <h3>Coins</h3>
              <strong>
                Selamat datang di Store
                <span class="text-white">Jacuzzi Craft</span>.<br /><br />
                Silahkan pilih JCash yang ingin kamu beli. jika ada kendala.
              </strong>
            </div>
            <!-- Recent Payments -->
            <div class="recent-payments bg-dark text-light p-3 rounded mb-3">
              <h3>RECENT PAYMENTS</h3>
              <div class="d-flex flex-wrap">
                <div v-for="(item, index) in recentUsers.data" :key="index">
                  <div v-if="tooltips[index]" class="tooltip">
                    <div class="tooltip-pad">
                      <h3>{{ item.order_id }}</h3>
                      <p>{{ formatNumber(item.product.value) }}</p>
                      <h3>JCash</h3>
                    </div>
                  </div>
                  <img
                    @mouseover="showTooltip(index)"
                    @mouseleave="hideTooltip(index)"
                    :src="`https://minotar.net/avatar/${item.unique_id}`"
                    alt="Player Icon"
                    class="recent-payment-icon me-2"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Store Info Wrap -->
          <div class="col-md-9">
            <!-- Checkout Section -->
            <div class="checkout-section bg-dark text-light p-3 rounded mb-3">
              <form id="checkoutForm">
                <!-- Shopping Cart -->
                <div class="shopping-cart">
                  <h5>
                    <i class="bi bi-cart me-2 icon-yellow"></i>
                    Shopping Cart
                  </h5>
                  <div
                    class="d-flex align-items-center bg-secondary text-light p-2 rounded mb-2"
                  >
                    <img
                      :src="
                        isJcash == 1000
                          ? `/img/1000.webp`
                          : isJcash == 2500
                          ? `/img/2500.webp`
                          : isJcash == 5000
                          ? `/img/5000.webp`
                          : isJcash == 10000
                          ? `/img/10000.webp`
                          : isJcash == 20000
                          ? `/img/20000.webp`
                          : isJcash == 30000
                          ? `/img/30000.webp`
                          : isJcash == 50000
                          ? `/img/50000.webp`
                          : isJcash == 100000
                          ? `/img/100000.webp`
                          : isJcash == 300000
                          ? `/img/300000.webp`
                          : `/img/300000.webp`
                      "
                      alt="Product 1"
                      class="img-fluid product-img jcash-checkout"
                    />
                    <div class="flex-grow-1">
                      <span>{{ formatNumber(isJcash) }} Jcash</span><br />
                      <span>Rp. {{ formatNumber(isTotal) }}</span>
                    </div>
                  </div>
                  <div
                    class="cart-summary d-flex justify-content-between bg-secondary text-light p-2 rounded"
                  >
                    <span>Total Harga:</span>
                    <span>Rp. {{ formatNumber(isTotal) }}</span>
                  </div>
                </div>
              </form>
            </div>

            <!-- Coupon Code -->
            <!-- <div class="coupon-code bg-dark text-light p-3 rounded mb-3">
              <h5 class="mb-3">
                <i class="bi bi-gift me-2 icon-yellow"></i> Masukkan kode kupon
              </h5>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control bg-secondary m-0"
                  style="color: white"
                  placeholder="Masukkan kode kupon kesini"
                  v-model="couponCode"
                />
                <button
                  type="button"
                  class="btn btn-warning"
                  @click="applyCoupon"
                >
                  Redeem
                </button>
              </div>
            </div> -->

            <!-- Payment Method -->
            <div class="payment-method bg-dark text-light p-3 rounded mb-3">
              <h5>
                <i class="bi bi-credit-card me-2 icon-yellow"></i> Payment
                Method
              </h5>
              <hr />
              <div
                class="payment-option"
                :class="{ selected: selectedPaymentMethod === option.value }"
                v-for="option in paymentOptions"
                :key="option.id"
              >
                <input
                  type="radio"
                  :id="option.id"
                  name="payment"
                  :value="option.value"
                  v-model="selectedPaymentMethod"
                  class="form-check-input"
                />
                <label :for="option.id" class="form-check-label">{{
                  option.label
                }}</label>
              </div>
            </div>

            <!-- Payment Section -->
            <div class="bayar-section bg-dark text-light p-3 rounded mb-3">
              <h5><i class="bi bi-cart-check-fill icon-yellow"></i> Bayar</h5>
              <form @submit.prevent="submitPayment">
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Nomor Telepon Anda"
                  class="form-control bg-secondary mb-2"
                  v-model="name"
                  required
                  @input="validateNumber"
                />
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email Anda"
                  class="form-control bg-secondary mb-2"
                  v-model="email"
                  required
                />
                <div class="form-check mb-2">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    v-model="termsAccepted"
                    class="form-check-input"
                    required
                  />
                  <label for="terms" class="form-check-label">
                    Saya setuju dengan
                    <a
                      href="#"
                      class="text-warning"
                      data-bs-toggle="modal"
                      data-bs-target="#termsModal"
                      >Syarat dan Ketentuan</a
                    >
                    <span
                      >Saya tahu bahwa transaksi bersifat final dan tidak bisa
                      dikembalikan.</span
                    >
                  </label>
                </div>
                <button type="submit" class="btn btn-success">
                  Lanjut Bayar
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <footer class="text-center p-3 bg-dark text-light">
      Copyright ©️ Jacuzzi Craft 2024. All Rights Reserved. We are not
      affiliated with Mojang AB.
    </footer>

    <!-- modal  -->
    <div
      class="modal fade"
      id="termsModal"
      tabindex="-1"
      aria-labelledby="termsModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content bg-dark text-light">
          <div class="modal-header">
            <h5
              class="modal-title"
              id="termsModalLabel"
              style="font-weight: bold"
            >
              Syarat dan Ketentuan
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-left">
            <h5 style="font-weight: bold">
              Syarat dan Ketentuan Pembelian Koin di Server Jacuzzi Craft
            </h5>
            <ul>
              <p>1. Umum:</p>
              <li>
                Dengan melakukan pembelian koin di server ini, Anda setuju untuk
                mematuhi semua syarat dan ketentuan yang tercantum di bawah ini.
                Syarat dan ketentuan ini berlaku untuk semua transaksi yang
                dilakukan melalui sistem koin di server Minecraft Jacuzzi Craft.
              </li>
            </ul>
            <ul>
              <p>2. Pembayaran Penggunaan Koin.</p>
              <li>
                Koin adalah mata uang virtual yang digunakan di dalam server
                Jacuzzi Craft untuk membeli berbagai item seperti Rank, RPG Key,
                Premium Key, dan senjata RPG.
              </li>
              <li>
                Koin hanya dapat digunakan di dalam server dan tidak memiliki
                nilai di luar server Minecraft Jacuzzi Craft.
              </li>
              <li>
                Pembelian koin dilakukan melalui platform pembayaran yang telah
                disediakan. Setelah transaksi berhasil, koin akan otomatis
                ditambahkan ke akun Anda.
              </li>
              <li>
                Harga koin dapat berubah sewaktu-waktu tanpa pemberitahuan
                terlebih dahulu. Namun, perubahan harga tidak akan mempengaruhi
                transaksi yang sudah selesai.
              </li>
            </ul>
            <ul>
              <p>3. Kebijakan Pengembalian</p>
              <li>
                Produk yang sudah dibeli tidak dapat dikembalikan. Semua
                pembelian bersifat final dan tidak ada pengembalian uang dalam
                bentuk apapun, termasuk tetapi tidak terbatas pada koin, item,
                atau produk digital lainnya.
              </li>
              <li>
                terjadi kesalahan dalam pembelian (misalnya, jumlah koin yang
                salah), harap segera hubungi tim dukungan kami dalam waktu 24
                jam setelah pembelian. Kami akan meninjau masalah tersebut,
                tetapi keputusan akhir tetap berada di pihak manajemen.
              </li>
            </ul>

            <ul>
              <p>4. Pemanfaatan Produk</p>
              <li>
                Semua item dan layanan yang dibeli dengan koin bersifat mutlak
                dan tidak dapat dipindahkan, dikembalikan, atau dipertukarkan
                dengan item lain kecuali ada kebijakan khusus yang diatur oleh
                server.
              </li>
              <li>
                Penggunaan koin dan item yang diperoleh harus sesuai dengan
                aturan dan kebijakan server. Penyalahgunaan atau pelanggaran
                terhadap aturan server dapat menyebabkan akun Anda dibatasi atau
                diblokir tanpa pengembalian dana.
              </li>
            </ul>

            <ul>
              <p>5. Akun dan Keamanan</p>
              <li>
                Anda bertanggung jawab penuh atas keamanan akun Anda. Jangan
                pernah membagikan detail akun Anda kepada orang lain.
              </li>
              <li>
                Kami tidak bertanggung jawab atas kehilangan koin atau item
                akibat akses tidak sah ke akun Anda.
              </li>
            </ul>

            <ul>
              <p>6. Perubahan Syarat dan Ketentuan</p>
              <li>
                Kami berhak untuk mengubah syarat dan ketentuan ini kapan saja.
                Perubahan akan diumumkan di server atau melalui platform
                komunikasi yang tersedia. Dengan melanjutkan penggunaan server
                setelah perubahan, Anda setuju untuk mematuhi syarat dan
                ketentuan yang telah diubah.
              </li>
            </ul>

            <ul>
              <p>7. Hukum yang Berlaku</p>
              <li>
                Syarat dan ketentuan ini diatur oleh hukum yang berlaku di
                negara tempat server Jacuzzi Craft beroperasi. Segala sengketa
                yang timbul dari penggunaan koin dan layanan di server ini akan
                diselesaikan melalui jalur hukum yang berlaku di yurisdiksi
                tersebut.
              </li>
            </ul>

            <ul>
              <p>8. Kontak</p>
              <li>
                Jika Anda memiliki pertanyaan atau memerlukan bantuan terkait
                pembelian koin atau produk lainnya, silakan hubungi tim dukungan
                kami melalui Discord yang telah disediakan.
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Logout -->
    <div
      class="modal fade"
      id="logoutModal"
      tabindex="-1"
      aria-labelledby="logoutModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content bg-dark text-light">
          <div class="modal-header">
            <h5 class="modal-title" id="logoutModalLabel">
              Logout Confirmation
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">Apakah kamu yakin ingin keluar?</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-danger" @click="confirmLogout">
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";
import Cookies from "js-cookie";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";

export default {
  data() {
    return {
      pageTitle: "Jacuzzi Webstore | Checkout",
      tooltips: [],
      recentUsers: [],
      promos: [],
      checkUsername: {},
      quantity: 1,
      isLogin: "",
      isTotal: "",
      isJcash: "",
      isBed: null,
      couponCode: "",
      selectedPaymentMethod: "",
      name: "",
      email: "",
      termsAccepted: false,
      paymentOptions: [
        { id: "qris", value: 1, label: "Qris" },
        { id: "gopay", value: 2, label: "Gopay" },
        { id: "bank-permata", value: 3, label: "Bank Permata" },
        { id: "bank-cimb", value: 4, label: "Bank CIMB" },
        { id: "bank-bni", value: 5, label: "Bank BNI" },
        { id: "bank-bri", value: 6, label: "Bank BRI" },
        { id: "bank-lainnya", value: 7, label: "Bank Lainnya" },
        {
          id: "pembayaran-lainnya",
          value: 8,
          label: "Pembayaran Lainnya",
        },
      ],
      totalPrice: 10000,
    };
  },
  computed: {
    formattedTotalPrice() {
      return this.totalPrice.toLocaleString("id-ID");
    },
  },
  mounted() {
    document.title = this.pageTitle;
    this.listRecent();
    this.isLogin = Cookies.get("user-profile");
    this.isBed = Cookies.get("bedrock");
    this.isTotal = Cookies.get("Total");
    this.isJcash = Cookies.get("jcash");
  },
  watch: {
    pageTitle(newTitle) {
      document.title = newTitle;
    },
  },
  methods: {
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    goToHomePage() {
      this.$router.push("/");
    },
    showTooltip(index) {
      this.tooltips[index] = true;
    },
    hideTooltip(index) {
      this.tooltips[index] = false;
    },
    removeItem() {
      this.quantity = 0;
      this.calculateTotal();
    },
    calculateTotal() {
      this.totalPrice = this.subTotal * this.quantity;
    },
    validateNumber(event) {
      const value = event.target.value;
      event.target.value = value.replace(/\D/g, "");
    },
    confirmLogout() {
      Cookies.remove("user-profile");
      Cookies.remove("bedrock");
      Cookies.remove("Total");
      Cookies.remove("jcash");
      this.$router.push("/");
      setTimeout(() => {
        location.reload();
      }, 500);
    },
    submitPayment() {
      // Prepare the payload
      const payload = {
        product_id: Cookies.get("id-produk"),
        nick_name: this.isLogin,
        phone_number: this.name,
        email: this.email,
        coupon: this.coupon || "",
        payment_method: this.selectedPaymentMethod,
      };
      axios
        .post(
          `${process.env.VUE_APP_CONTENT}/v1/midtrans/create-payment`,
          payload
        )
        .then((response) => {
          console.log(response, "ini anjng");
          console.log(response.data, "ini tolol");
          new Noty({
            type: "success",
            layout: "top",
            text: "Berhasil Memesan",
            timeout: 3000,
          }).show();
          setTimeout(() => {
            window.location.replace(response.data.data.payment_url);
          }, 1500);
        })
        .catch((error) => {
          new Noty({
            type: "error",
            layout: "top",
            text: error,
            timeout: 3000,
          }).show();
        });
    },
    listRecent() {
      axios
        .get(
          `${process.env.VUE_APP_CONTENT}/v1/midtrans/recent-purchase-history?limit=5`
        )
        .then((response) => {
          this.recentUsers = response.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<!-- css modal -->
<style scoped>
.tooltip {
  position: absolute;
  top: 275px;
}
.modal-body {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #26272a;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 20px;
}

.text-left {
  text-align: justify;
}

h1 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

ul {
  margin: 10px 0;
  list-style-type: disc;
  padding-left: 20px; /* Sesuaikan sesuai kebutuhan */
}

p {
  margin-left: -16px;
  margin-bottom: 0rem !important;
  font-weight: bold;
}

li {
  margin-bottom: 5px;
}
</style>
